/*
 * 업무구분   : 설계청약
 * 화면 명    : MSPPI770M
 * 화면 설명  : 가입설계지원요청
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="가입설계지원요청" :topButton="false" @on-header-left-click="fn_alertOpen"><!-- type-popup -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="pb80">
        
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
          <p class="fs16rem">가입설계 동의가 유효한 고객은 가입설계/청약 생성까지 지원가능하고, 그 외에는 가상고객 설계만 지원 가능합니다.</p>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
          <span class="contain-title must">고객명</span>
          <mo-text-field class="form-input-name" v-model="vCustNm" underline clearable placeholder="고객명을 입력해주세요."/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title must">성별</span>
          </div>
           <mo-segment-wrapper solid primary v-model="vCustGender" class="chip-type-wrap chip-btn__nowrap">
            <mo-segment-button value="1">남성</mo-segment-button>
            <mo-segment-button value="2">여성</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title">생년월일</span>
          </div>
          <ur-box-container class="ns-add-box">
            <mo-date-picker bottom v-model="vCustDate" placeholder="생년월일을 선택해주세요." class="ns-date-picker flex-auto" />
          </ur-box-container>  
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <span class="contain-title">상품명</span>
          <mo-text-field class="form-input-name" v-model="vPrdtNm" underline clearable placeholder="상품명을 입력해주세요."/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title">요청(예상)보험료</span>
          </div>
          <div class="ns-certify-sed-code row-text">
            <mo-decimal-field numeric class="full" v-model="vExptPrm" underline placeholder="요청보험료를 입력해주세요." />
            <span class="right-text">원</span>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title">요청상세내용</span>
          </div>
          <p class="crTy-orange2">
            <mo-icon icon="msp-or-alert2"></mo-icon>주의 : 불필요한 개인정보는 입력하지 마십시오.
          </p>
          <div class="content-area mt10">
            <mo-text-area class="full" rows="7" v-model="vReqDtlCntnt" placeholder="주계약, 필수담보, 직업, 납기 등 추가 입력사항을 입력해주세요." />
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title must">매니저</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field class="form-input-name full" v-model="vMngerInfo" @click="fn_OpenMSPBC413P()" @click-icon="fn_OpenMSPBC413P()" searchable underline placeholder="담당 매니저를 검색해주세요." />
          </ur-box-container>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
    
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_alertOpen">취소</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_confirmYes">요청</mo-button>
        </div>
      </ur-box-container>
    </template>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import piCommonUtil from '@/ui/pi/common/piCommonUtil'
  import MSPBC413P from '@/ui/bc/MSPBC413P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역
     * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
     * - screenId : 화면ID
     * - components : UI TAG 컴포넌트 정의
     ***********************************************************************************/
    name: "MSPPI770M",
    screenId: "MSPPI770M",
    components: {
      "MSPBC413P" : MSPBC413P //매니저선택 팝업
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역
     * - 각단계별 예약 함수에 맞게 스크립트 작성
     ***********************************************************************************/
    /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
    created() {
      //GA매니저 권한
      this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN

      // GA매니저는 접근불가
      if(this.vGssAuthYN == 'Y') {
        this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: 'GA설계사 전용화면입니다.',
          title_pos_btn: '확인'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
          },
          onPopupClose: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
            }
          }
        })
      }

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_alertOpen)
    },
    /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
    mounted() {
      
    },
    /** Step-8 인스턴스가 Remove 된 후 호출 */
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_alertOpen)
    },
    /***********************************************************************************
     * 화면 전역 변수 선언 영역
     * - UI 바인딩 및 스크립트 화면 전역변수 정의
     ***********************************************************************************/
    data() {
      return {
        vUserInfo: this.getStore('userInfo').getters.getUserInfo, // 사용자 정보
        vGssAuthYN: '', //GA매니저 권한
        vCurrYmd: moment(new Date()).format('YYYYMMDD'), // 현재일자
        vCurrTm: moment(new Date()).format('HHmmss'), // 현재시간
        vCustNm: '', // 고객명
        vCustGender: '1', // 성별
        vCustGenderNm: '남', // 성별명
        vCustDate: '', // 생년월일
        vPrdtNm: '', // 상품명
        vExptPrm: '', // 예상 보험료
        vReqDtlCntnt: '', // 요청상세내용
        vMngerId: '', // 매니저ID
        vMngerNm: '', // 매니저명
        vMngerInfo: '' // 매니저정보
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
     *   지정 함수는 반드시 리턴 하는 함수로 작성
     *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
     ***********************************************************************************/
    computed: {

    },
    /***********************************************************************************
     * watch 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
     *   (변수에 대한 값 변경시 반응형 콜백 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *       userNm : function() { this.message + ' 님 안녕하세요!'}
     ***********************************************************************************/
    watch: {
      vCustGender () {
        if (this.vCustGender == '2') {
          this.vCustGenderNm = '여'
        }else {
          this.vCustGenderNm = '남'
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        this.$router.push({name: 'MSPBC002M'})
      },
      /******************************************************************************
      * Function명  : fn_init
      * 설명        : 초기화
      ******************************************************************************/
      fn_init() {
        this.vCustNm = '' // 고객명
        this.vCustGender = '1' // 성별
        this.vCustDate = '' // 생년월일
        this.vPrdtNm = '' // 상품명
        this.vExptPrm = '' // 예상 보험료
        this.vReqDtlCntnt = '' // 요청상세내용
        this.vMngerId = '' // 매니저ID
        this.vMngerNm = '' // 매니저명
        this.vMngerInfo = '' // 매니저정보

        document.getElementById('scroll_MSPPI770M').scrollTop = 0
      },
      /******************************************************************************
      * Function명 : fn_OpenMSPBC413P
      * 설명       : 매니저선택 팝업 호출
      ******************************************************************************/
      fn_OpenMSPBC413P () {
        let lv_Vm = this
        this.popup413 = lv_Vm.$bottomModal.open(MSPBC413P, {
          properties: {
          },
          listeners: {
            // 확인
            onPopupConfirm: (pData) => {
              lv_Vm.vMngerId = pData.eplyNo // 매니저ID
              lv_Vm.vMngerNm = pData.gssEplyNm // 매니저명
              lv_Vm.vMngerInfo = pData.gssEplyNm + "(" + pData.eplyNo + ")"

              lv_Vm.$bottomModal.close(lv_Vm.popup413)
            },
            // 닫기
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.popup413)
            }
          }
        },
        {
          properties : {noHeader : true}
        })
      },
      /*********************************************************
       * Function명: fn_alertOpen
       * 설명: 취소버튼 클릭시 알럿 오픈
       *********************************************************/
      fn_alertOpen() {
        this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: '입력한 내용은 저장되지 않습니다. 가입설계지원요청을 취소하시겠습니까?',
          title_neg_btn:'취소',
          title_pos_btn: '확인'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.alertPop);
            }
          }
        })
      },
      /*********************************************************
       * Function명: fn_confirmOpen
       * 설명: 등록완료시 알럿 오픈
       *********************************************************/
      fn_confirmOpen() {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: '가입설계지원 요청이 완료되었습니다.',
          title_pos_btn: '확인'
        },
        listeners: {
          onPopupConfirm: () => {
            // 초기화
            this.fn_init()
            this.$bottomModal.close(this.confirmPop);
          },
          onPopupClose: () => {
            this.fn_init()
            this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_confirmValidCheck
      * 설명       : 필수값 체크
      ******************************************************************************/
      fn_confirmValidCheck () {
        let isValid = true

        // 고객명 체크
        if (piCommonUtil.isEmpty(this.vCustNm)) {
          this.getStore('confirm').dispatch('ADD', '고객명을 입력해주세요.')
          isValid = false
        }
        // 성별 체크
        else if (piCommonUtil.isEmpty(this.vCustGender)) {
          this.getStore('confirm').dispatch('ADD', '성별을 선택해주세요.')
          isValid = false
        }
        // 매니저 체크
        else if (piCommonUtil.isEmpty(this.vMngerInfo)) {
          this.getStore('confirm').dispatch('ADD', '매니저를 선택해주세요.')
          isValid = false
        }

        return isValid
      },
      /******************************************************************************
      * Function명 : fn_confirmYes
      * 설명       : 설계요청
      ******************************************************************************/
      fn_confirmYes () {
        // 필수값 체크
        if(!this.fn_confirmValidCheck()) return
        
        let lv_Vm = this
        const trnstId = 'txTSSPI08I1'
        const auth = 'I'
        let pParams = {}
        let dma_GAM00010DVO = {}
        let pCustNm = lv_Vm.vCustNm + '(' + lv_Vm.vCustGenderNm + ')'

        let reqDtlCntnt = '고 객 명 : ' + pCustNm + '/'
                        + '생년월일 : ' + lv_Vm.vCustDate + '/'
                        + '상품종류 : ' + lv_Vm.vPrdtNm + '/'
                        + '요청보험료 : ' + lv_Vm.$bizUtil.numberWithCommasByNumber(lv_Vm.vExptPrm) + '/'
                        + '요청방법 : ' + '모바일/'
                        + '추가입력 : ' + lv_Vm.vReqDtlCntnt
        
        dma_GAM00010DVO.mngerId = lv_Vm.vMngerId // 매니저ID : 선택한 매니저ID
        dma_GAM00010DVO.fpEno = lv_Vm.vUserInfo.userId // FP사번 : 사용자ID(GA설계사)
        dma_GAM00010DVO.reqYmd = lv_Vm.vCurrYmd // 요청일자
        dma_GAM00010DVO.reqTm = lv_Vm.vCurrTm // 요청시간
        dma_GAM00010DVO.reqDtlCntnt = reqDtlCntnt // 요청내용
        dma_GAM00010DVO.recmEplyScCd = '30' // 추천인구분코드(30:설계지원)

        pParams.dma_GAM00010DVO = dma_GAM00010DVO
        pParams.fnScCd = '03', // 기능구분(03:가입설계지원요청 등록)
        
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.msgComm.msgCd === '"EFWS026"') {
            lv_Vm.fn_confirmOpen()
          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '등록시 오류가 발생하였습니다.')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
        
      }
      
    }
  };
</script>